export const DYNAMO_TABLE_SETTINGS = {
  NEWSLETTER: {
    NAME: 'newsletter_subscribers',
    PRIMARY_KEY: 'subscribers'
  }
}

export const DYNAMO_TABLE = {
  INGREDIENTS: 'core_ingredients',
  SLUGS: 'core_slugs',
  PHOTOS: 'core_photos',
  FAQ: 'core_faq',
  ARTICLES: 'core_articles',
  BOOKS: 'core_books',
  PAGES: 'core_pages',
  RECIPES: 'core_recipes',
  APPLIANCES: 'core_appliances'
}
