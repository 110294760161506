
import { PAGE_SCHEME_TYPE } from '~/constants/pageScheme'
import AFaqSection from '~/components/common/AFaqSection/index.vue'
import { FAQ_PAGE } from '~/constants/faq'
import FaqService from '~/api/services/FaqService'

export default {
  name: 'HomePage',
  components: { AFaqSection },
  consts: {
    PAGE_TITLE_I18N_PATH: 'page_homepage.page_title',
    PAGE_DESCRIPTION_I18N_PATH: 'page_homepage.page_description_2',
    PAGE_DESCRIPTION_PARAGRAPH_1_I18N_PATH:
      'page_homepage.page_description.paragraph_1',
    PAGE_DESCRIPTION_PARAGRAPH_2_I18N_PATH:
      'page_homepage.page_description.paragraph_2',
    PAGE_DESCRIPTION_PARAGRAPH_3_I18N_PATH:
      'page_homepage.page_description.paragraph_3',
    PAGE_DESCRIPTION_PARAGRAPH_4_I18N_PATH:
      'page_homepage.page_description.paragraph_4',
    PAGE_DESCRIPTION_PARAGRAPH_5_I18N_PATH:
      'page_homepage.page_description.paragraph_5',
    PAGE_DESCRIPTION_PARAGRAPH_6_I18N_PATH:
      'page_homepage.page_description.paragraph_6'
  },
  async asyncData({ app, i18n }) {
    try {
      const faqList = await FaqService.getFaqsByPage({
        page: FAQ_PAGE.HOME,
        locale: i18n.locale
      })

      return { faqList }
    } catch (err) {
      app.$notificationShow({
        type: 'error',
        message: 'Error while fetching faq'
      })
    }
  },
  data() {
    return {
      faqList: []
    }
  },
  head() {
    return this.$helper.generatePageMeta({
      ctx: this,
      titleI18nPath: this.$options.consts.PAGE_TITLE_I18N_PATH,
      descriptionI18nPath: this.$options.consts.PAGE_DESCRIPTION_I18N_PATH,
      schemas: this.generatePageSchemas()
    })
  },
  methods: {
    generatePageSchemas() {
      return [
        {
          type: PAGE_SCHEME_TYPE.WEB_SITE,
          data: {
            headline: this.$translateOrDefault(
              this.$options.consts.PAGE_TITLE_I18N_PATH
            ),
            thumbnailUrl: this.$helper.buildFullUrl(
              '/no-lectin-food-social-sharing.png'
            ),
            isBasedOnUrl: this.$helper.buildFullUrl(),
            text: [
              this.$translateOrDefault(
                this.$options.consts.PAGE_TITLE_I18N_PATH
              ),
              this.$translateOrDefault(
                this.$options.consts.PAGE_DESCRIPTION_PARAGRAPH_1_I18N_PATH
              )
            ].join(' - '),
            description: this.$translateOrDefault(
              this.$options.consts.PAGE_DESCRIPTION_I18N_PATH
            )
          }
        }
      ]
    }
  }
}
