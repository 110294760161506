import DDBClient from '../DDBClient'
import { DYNAMO_TABLE } from '../../constants/dynamoTable'

class FaqService {
  async getFaqsByPage({ page, locale }) {
    const params = {
      TableName: DYNAMO_TABLE.FAQ,
      KeyConditionExpression: 'pk = :pk and begins_with(sk, :sk)',
      ExpressionAttributeValues: {
        ':pk': page,
        ':sk': locale
      }
    }

    const result = await DDBClient.query(params)

    return result.Items
  }
}

export default new FaqService()
