
export default {
  name: 'FaqSectionImpression',
  data() {
    return {
      selectedIcon: null,
      icons: ['$thumbUp', '$thumbDown']
    }
  },
  methods: {
    selectIcon(icon) {
      this.selectedIcon = icon
    },
    getIconDynamicClass(icon) {
      return icon === this.selectedIcon ? 'v-btn--active' : ''
    }
  }
}
