
import FaqSectionImpression from '~/components/common/AFaqSection/FaqSectionImpression/index.vue'

export default {
  name: 'AFaqSection',
  components: { FaqSectionImpression },
  props: {
    faqList: {
      type: Array,
      required: true
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.fixAccessibilityIssues()
  },
  methods: {
    /**
     * According to this Vuetify's bug:
     * https://github.com/vuetifyjs/vuetify/issues/15514
     */
    async fixAccessibilityIssues() {
      if (!this.$refs.faqItems) return

      setTimeout(() => {
        this.$refs.faqItems.forEach(ref => {
          // Set aria-expanded attribute to null for each top-level v-expansion-panel
          if (ref.$el) {
            ref.$el.removeAttribute('aria-expanded')
          }
        })
      })
    }
  }
}
